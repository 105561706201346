import React, { useCallback, useEffect, useState } from "react"
import Button from "../Button"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Icon from "../Icon"
import Footer from "../Footer"
import { toast } from "react-toastify"

const MENU = [
  {
    text: "Giới thiệu",
    url: "/gioi-thieu-giai-thuong",
  },
  {
    text: "Vạn Xuân Awards 2024",
    subMenu: [
      {
        text: "Vạn Xuân 2024",
        url: "/van-xuan",
      },
      {
        text: "Vạn Xuân Classic 2024",
        url: "/van-xuan-classic",
      },
      {
        text: "Vạn Xuân Stars 2024",
        url: "/van-xuan-stars",
      },
    ],
  },
  {
    text: "Chuyên gia đồng hành",
    url: "/chuyen-gia-dong-hanh",
  },
  {
    text: "Tin tức / Sự kiện",
    url: "/tin-tuc-cuoc-thi",
  },
  {
    text: "Danh sách thắng giải",
    url: "/danh-sach-thang-giai",
  },
]

const Header = ({ className = "" }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const currentURL = location.pathname
  const [visibleMenu, setVisibleMenu] = useState(false)

  useEffect(() => {
    if (visibleMenu) {
      document.getElementById("mainLayoutId").classList.add("overflow-y-hidden")
    } else {
      document.getElementById("mainLayoutId").classList.remove("overflow-y-hidden")
    }
  }, [visibleMenu])

  const handleClickItem = useCallback((item) => {
    if (item.sectionId) {
      navigate(item.url, {
        state: {
          sectionId: item.sectionId,
        },
      })
    } else {
      if (item.url) {
        navigate(item.url)
      }
    }
  }, [])

  return (
    <header
      className={`fixed top-0 left-0 z-[99] w-full bg-header ${
        visibleMenu ? "md:flex md:h-full md:flex-col md:bg-background" : ""
      } ${className}`}
    >
      <div
        className={
          "mx-auto flex w-full max-w-[1568px] items-center justify-between px-4 py-10 md:py-6"
        }
      >
        <Link to="/" className="z-10 flex items-center gap-x-4 text-left">
          <img src={"/images/logo.png"} alt="Logo" className={"w-[120px] md:w-[80px]"} />
          {visibleMenu && (
            <img
              src={"/images/logo-text.svg"}
              alt="Logo"
              className={"hidden md:block md:w-[174px]"}
            />
          )}
        </Link>
        <button className={"hidden md:block"} onClick={() => setVisibleMenu(!visibleMenu)}>
          <Icon name={visibleMenu ? "close" : "menu"} />
        </button>
        <div className={"flex items-center gap-x-10 md:hidden"}>
          <ul className={"flex items-center gap-x-4"}>
            <li>
              <Link to={"/"}>
                <Icon name={"home"} />
              </Link>
            </li>
            {MENU.map((item) => {
              const isActive = item.url !== "/" && currentURL.includes(item.url)
              return (
                <li
                  onClick={() => handleClickItem(item)}
                  key={item.text}
                  className={`group relative cursor-pointer py-2 text-14 ${
                    isActive ? "border-b-1 border-white" : ""
                  }`}
                >
                  <span className={"uppercase hover:font-bold"}>{item.text}</span>

                  {item?.subMenu && (
                    <ul
                      className={
                        "absolute top-[100%] left-0 hidden w-[180px] space-y-4 rounded-md bg-white p-4 text-black group-hover:block"
                      }
                    >
                      {item.subMenu.map((i) => {
                        const isSelected = item.url !== "/" && currentURL === i.url
                        return (
                          <li onClick={() => handleClickItem(i)}>
                            <span className={`hover:font-bold ${isSelected ? "border-b-1" : ""}`}>
                              {i.text}
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>
          <div className={"flex items-center gap-x-6"}>
            <Button
              onClick={() => {
                navigate("/dang-ky-du-thi")
              }}
              className={"h-[45px]"}
            >
              <b className={"text-14"}>Đăng ký tham gia</b>
            </Button>
            <Button
              onClick={() => {
                navigate("/nop-bai-du-thi")
              }}
              className={"h-[45px]"}
            >
              <b className={"text-14"}>Nộp bài thi</b>
            </Button>
          </div>
        </div>
      </div>
      <div
        className={`z-[99] h-0 flex-1 overflow-y-auto transition-all duration-500 ${
          visibleMenu ? "md:opacity-1 md:h-auto" : "md:h-0 md:opacity-0"
        }`}
      >
        <Footer isHeaderMobile onClickItem={() => setVisibleMenu(false)} />
      </div>
    </header>
  )
}

export default Header
