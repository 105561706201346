import { MessengerChat } from "react-messenger-chat-plugin"
import { ToastContainer } from "react-toastify"

import MainRoutes from "router/MainRoutes"
import MainLayout from "./layouts/MainLayout"
import "./App.css"

function App() {
  return (
    <>
      <MainLayout>
        <MainRoutes />
        <ToastContainer />
        <MessengerChat
          pageId="107351212459812"
          language="vi_VN"
          bottomSpacing={100}
          greetingDialogDisplay={"show"}
        />
      </MainLayout>
    </>
  )
}

export default App
