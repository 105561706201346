const Title = ({ icon = "", text, className = "" }) => {
  return (
    <div className={`flex items-center gap-x-4 md:w-full ${className}`}>
      {icon && <img src={`/images/${icon}.png`} alt="Icon" className={"w-[112px] md:w-10"} />}
      <h2
        className={
          "border-blueGradient font-gilroy relative pb-4 text-54 before:h-[5px] md:flex-1 md:pb-2 md:text-18 md:before:h-[3px]"
        }
      >
        <b>{text}</b>
      </h2>
    </div>
  )
}

export default Title
