import { useEffect, useState } from "react"

import Footer from "components/Footer";
import Header from "components/Header"
import Icon from "components/Icon"
import { loginFShare } from "services/api/fshare"
import { setAuthToken } from "services/axiosFShare"

const MainLayout = ({ children }) => {
  const [showArrowButton, setShowArrowButton] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setShowArrowButton(!!currentScrollPos)
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    ;(async() => {
      try {
        const payload = {
          "user_email": process.env.REACT_APP_FSHARE_USER_EMAIL,
          "password": process.env.REACT_APP_FSHARE_PASSWORD,
          "app_key": process.env.REACT_APP_FSHARE_APP_KEY,
        }
        const res = await loginFShare(payload)

        if (res.data) {
          setAuthToken(res.data.token, res.data.session_id)
          localStorage.setItem('FSHARE_LOGIN_TOKEN', res.data.token)
          localStorage.setItem('FSHARE_LOGIN_SESSION_ID', res.data.session_id)
        }
      } catch (error) {}
    })()
  }, [])

  return (
    <div className="bg-background" id="mainLayoutId">
      <Header />
      <div className="min-h-screen">{children}</div>
      <Footer />
      {
        showArrowButton &&
        <button type={'button'} className={'flex items-center justify-center w-12 aspect-square rounded-full bg-pink fixed bottom-6 right-6 z-[98]'} onClick={() => {
          document.getElementsByTagName("html")?.[0]?.scrollTo({ top: 0, behavior: 'smooth' })
        }}>
          <Icon name={'arrow-up'} size={32} />
        </button>
      }
    </div>
  );
};

export default MainLayout;
