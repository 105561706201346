import React from "react"
import Title from "components/Title"
import Button from "components/Button"

export const AWARD_CATEGORIES = [
  {
    text: "VAN XUAN",
    textVn: "Vạn Xuân",
    url: "/van-xuan",
  },
  {
    text: "VAN XUAN CLASSIC",
    textVn: "Vạn Xuân Classic",
    url: "/van-xuan-classic",
  },
  {
    text: "VAN XUAN STARS",
    textVn: "Vạn Xuân Stars",
    url: "/van-xuan-stars",
  },
]

const AwardCategories = ({ onClickItem }) => {
  return (
    <section className={"relative mx-auto h-full max-w-[1340px] px-4 py-[66px] md:py-14"}>
      <Title icon={"star"} text={"HẠNG MỤC GIẢI THƯỞNG"} />
      <div className={"relative mt-[104px] pl-[128px] md:mt-10 md:pl-0"}>
        <div
          className={
            "relative grid grid-cols-3 gap-x-10 md:grid-cols-1 md:gap-x-2 md:gap-y-4 md:px-8"
          }
        >
          {AWARD_CATEGORIES.map((item) => (
            <div
              key={item.text}
              className={`box-gradient opacity-1 group aspect-[2/3] cursor-pointer rounded-card`}
            >
              <div className={"h-full"}>
                <img src="/images/cup-bg.jpg" className={"h-full object-cover"} alt="" />
              </div>
              <div
                className={
                  "absolute top-0 left-0 right-0 bottom-0 rounded-[42px_20px] group-hover:bg-grayGradient"
                }
              />
              <div
                className={
                  "absolute top-0 left-0 flex w-full flex-col items-center gap-y-8 transition-all duration-500 group-hover:translate-y-[-100px] md:gap-y-4 md:group-hover:translate-y-[0px]"
                }
              >
                <img
                  src="/images/cup.png"
                  className={"h-full w-[128px] object-cover md:w-[96px]"}
                  alt=""
                />
                <h2
                  className={
                    "font-gilroy text-blueGradientGradient text-center text-54 md:flex-1 md:text-18"
                  }
                >
                  <b>{item.text}</b>
                </h2>
                <div onClick={onClickItem}>
                  <Button
                    url={item?.url}
                    className={
                      "h-[72px] w-[170px] opacity-0 transition-all duration-500 group-hover:opacity-[1] md:h-12 md:w-[90px] md:opacity-100"
                    }
                  >
                    <span className={"text-24 md:text-16"}>Chi tiết</span>
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default AwardCategories
